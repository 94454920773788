import "../media/css/typography.css"

import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import React, { ReactNode, useMemo, useState } from "react"

import { DEFAULT_LANGUAGE, Language, LanguageContext } from "./i18n"

const headerFont = {
  fontFamily: "'KosticRocCompressedMedium', sans-serif",
}

const headerStyles = {
  ...headerFont,
  margin: 0,
}

const materialUitheme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "#gatsby-focus-wrapper": {
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        },
        a: {
          color: "#CE6A14",
        },
        body: {
          backgroundColor: "#ffffff",
          fontFamily: "'KosticRoc', sans-serif",
        },
      },
    },
    MuiTypography: {
      root: { margin: "not_a_proper_value_as_a_workaround" },
    },
  },
  palette: {
    primary: { main: "#CE6A14" }, // orange
    secondary: { main: "#706F6F" }, // gray
  },
  typography: {
    fontFamily: "'KosticRoc', sans-serif",
    h1: headerStyles,
    h2: headerStyles,
    h3: headerStyles,
    h4: headerStyles,
    h5: headerStyles,
    h6: headerStyles,
    subtitle1: {
      fontSize: "1.25rem",
    },
  },
})

type Props = { element: ReactNode }

export default function Api({ element }: Props) {
  const [language, setLanguage] = useState<Language>(DEFAULT_LANGUAGE)

  const value = useMemo(
    () => ({
      language,
      setLanguage,
    }),
    [language]
  )

  return (
    <LanguageContext.Provider value={value}>
      <ThemeProvider theme={materialUitheme}>{element}</ThemeProvider>
    </LanguageContext.Provider>
  )
}
