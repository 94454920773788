import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby"
import React, { useContext } from "react"

import { DEFAULT_LANGUAGE } from "./constants"
import LanguageContext from "./LanguageContext"

type Props = {
  children: GatsbyLinkProps<unknown>["children"]
  className?: GatsbyLinkProps<unknown>["className"]
  title?: GatsbyLinkProps<unknown>["title"]
  to: string
}

export default function Link(props: Props) {
  const { language } = useContext(LanguageContext)
  const to =
    language === DEFAULT_LANGUAGE ? props.to : `/${language}${props.to}`

  return <GatsbyLink {...props} to={to} />
}
