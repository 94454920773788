import { createContext } from "react"

import { DEFAULT_LANGUAGE } from "./constants"
import { Language } from "./types"

const noop = () => null

type LanguageContextType = {
  language: Language
  // this should be done in config
  // eslint-disable-next-line no-unused-vars
  setLanguage: (language: Language) => void
}

const LanguageContext = createContext<LanguageContextType>({
  language: DEFAULT_LANGUAGE,
  setLanguage: noop,
})

export default LanguageContext
