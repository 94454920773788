// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-tsx": () => import("./../../../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-404-pl-tsx": () => import("./../../../src/pages/404.pl.tsx" /* webpackChunkName: "component---src-pages-404-pl-tsx" */),
  "component---src-pages-about-us-en-tsx": () => import("./../../../src/pages/about-us.en.tsx" /* webpackChunkName: "component---src-pages-about-us-en-tsx" */),
  "component---src-pages-about-us-pl-tsx": () => import("./../../../src/pages/about-us.pl.tsx" /* webpackChunkName: "component---src-pages-about-us-pl-tsx" */),
  "component---src-pages-classes-en-tsx": () => import("./../../../src/pages/classes.en.tsx" /* webpackChunkName: "component---src-pages-classes-en-tsx" */),
  "component---src-pages-classes-pl-tsx": () => import("./../../../src/pages/classes.pl.tsx" /* webpackChunkName: "component---src-pages-classes-pl-tsx" */),
  "component---src-pages-contact-en-tsx": () => import("./../../../src/pages/contact.en.tsx" /* webpackChunkName: "component---src-pages-contact-en-tsx" */),
  "component---src-pages-contact-pl-tsx": () => import("./../../../src/pages/contact.pl.tsx" /* webpackChunkName: "component---src-pages-contact-pl-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-pl-tsx": () => import("./../../../src/pages/index.pl.tsx" /* webpackChunkName: "component---src-pages-index-pl-tsx" */),
  "component---src-pages-membership-en-tsx": () => import("./../../../src/pages/membership.en.tsx" /* webpackChunkName: "component---src-pages-membership-en-tsx" */),
  "component---src-pages-membership-pl-tsx": () => import("./../../../src/pages/membership.pl.tsx" /* webpackChunkName: "component---src-pages-membership-pl-tsx" */),
  "component---src-pages-news-en-tsx": () => import("./../../../src/pages/news.en.tsx" /* webpackChunkName: "component---src-pages-news-en-tsx" */),
  "component---src-pages-news-pl-tsx": () => import("./../../../src/pages/news.pl.tsx" /* webpackChunkName: "component---src-pages-news-pl-tsx" */),
  "component---src-pages-partnership-en-tsx": () => import("./../../../src/pages/partnership.en.tsx" /* webpackChunkName: "component---src-pages-partnership-en-tsx" */),
  "component---src-pages-partnership-pl-tsx": () => import("./../../../src/pages/partnership.pl.tsx" /* webpackChunkName: "component---src-pages-partnership-pl-tsx" */),
  "component---src-pages-team-en-tsx": () => import("./../../../src/pages/team.en.tsx" /* webpackChunkName: "component---src-pages-team-en-tsx" */),
  "component---src-pages-team-pl-tsx": () => import("./../../../src/pages/team.pl.tsx" /* webpackChunkName: "component---src-pages-team-pl-tsx" */),
  "component---src-pages-timetable-en-tsx": () => import("./../../../src/pages/timetable.en.tsx" /* webpackChunkName: "component---src-pages-timetable-en-tsx" */),
  "component---src-pages-timetable-pl-tsx": () => import("./../../../src/pages/timetable.pl.tsx" /* webpackChunkName: "component---src-pages-timetable-pl-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

